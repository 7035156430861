<template>
  <div id="app-background"></div>
  <div id="global-modal"></div>

  <template v-if="!this.is_first_load">
    <router-view v-if="this.withoutBasePage" v-slot="{ Component }">
      <transition name="slow-opacity-fade" appear>
        <component v-show="!this.work_blocked" :is="Component" />
      </transition>
    </router-view>
    <template v-else>
      <transition name="slow-opacity-fade" appear>
        <BasePage v-show="!this.work_blocked" :ext_page_name="this.page_name"/>
      </transition>
      <Teleport to="#page-content">
        <router-view :pageNameChanger="this.setPageName"/>
      </Teleport>
    </template>
  </template>

  <WindowsController />

  <BlockPage  :is_disabled="!this.work_blocked"
              :display_load_status="this.is_first_load"
              @start_work_click="this.startWork"
            />

  <IsOfflineMessage />
  <IsExperementsProd />
</template>

<script>

import WindowsController from '@/windows/WindowsController.vue';

import BlockPage from '@/baseLayout/BlockPage.vue';
import getUserInfo from '@/assets/dataSources/auth/getUserInfo';
import getWebSiteStructure from '@/assets/dataSources/getWebSiteStructure.js';
import getTemplateStructure from '@/assets/dataSources/getTemplateStructure.js';
import BasePage from '@/baseLayout/BasePage';

import IsOfflineMessage from '@/baseLayout/topPanel/IsOfflineMessage.vue';
import IsExperementsProd from '@/baseLayout/topPanel/IsExperementsProd.vue';

export default {
  mounted() {
    let storageTheme = null; // localStorage.getItem('theme_type');
    let viewMode = null; // localStorage.getItem('view_mode');
    try {
      storageTheme = localStorage.getItem('theme_type');
      viewMode = localStorage.getItem('view_mode');
    } catch {}
    this.$store.commit('setTheme', storageTheme == null ? 'dark' : storageTheme);
    this.$store.commit('viewModeMut', viewMode == null ? 'light' : viewMode);

    window.escapeableElements.push(this.blockWork);
    this.loadBaseData();
  },
  unmounted() {
    const index = window.escapeableElements.indexOf(this.blockWork);
    if (index > -1) {
      window.escapeableElements.splice(index, 1);
    }
  },
  data: () => ({
    page_name: null,
    work_blocked: true,
    is_first_load: true,
  }),
  watch: {
    $route(to, from) {
      if (this.isAuthMode) {
        this.startWork();
      }

      if (String.comparePaths(to.path, from.path)) return;
      this.page_name = null;
    },
    getCriticalError() {
      if (this.getCriticalError == null) return;

      this.is_first_load = true;
      this.work_blocked = true;
    },
  },
  computed: {
    isAuthMode() {
      return (
        this.$route.name == 'Login' ||
        this.$route.name == 'Register' ||
        this.$route.name == 'ResetPassword' ||
        this.$route.name == 'AcceptAccount'
      );
    },
    withoutBasePage() {
      return this.$route.meta.noBasePage == true;
    },
    getCriticalError() {
      return this.$store.state.critical_error;
    },
  },
  components: {
    WindowsController,
    BlockPage,
    BasePage,
    IsOfflineMessage,
    IsExperementsProd,
  },
  methods: {
    startWork() {
      this.work_blocked = false;
      this.is_first_load = false;
    },
    blockWork() {
      if (this.work_blocked) {
        if (window.WORK_IN_APPLICATION) {
          window.sendMessageInApplication({
            type: 'close_app',
          });
        }
        return true;
      }
      this.work_blocked = true;
    },
    loadBaseData() {
      getUserInfo((data) => {
        this.$store.commit('user_obj_mut', data);
      });

      getWebSiteStructure((data) => {
        this.$store.commit('web_site_struct_mut', data);
      });

      getTemplateStructure((data) => {
        this.$store.commit('page_templates_struct_mut', data);
      });
    },
    setPageName(newName) {
      this.page_name = newName;
    },
  },
};

</script>
